export function pageFlowWithTokenRoutePathGenerator() {
  return `/page-flow/with-token`;
}

export function pageFlowCreateRoutePathGenerator(flow: string) {
  return `/page-flow/${flow}/create`;
}

export function pageFlowModifyRoutePathGenerator(flow: string, instance: string) {
  return `/page-flow/${flow}/${instance}`;
}
