import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractDialogTemplateComponent } from '../base/abstract-dialog-template.component';
import { MessageDialogResult, TextMessageDialogOptions } from '../../typing';
import { DialogBaseFooterComponent } from '../base/dialog-base-footer.component';

@Component({
  selector: 'cs-core-message-dialog-message-dialog-text-footer',
  standalone: true,
  imports: [DialogBaseFooterComponent],
  template: `<cs-core-message-dialog-dialog-base-footer [okData]="okData" (ok)="onClickOk()" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogTextFooterComponent extends AbstractDialogTemplateComponent<TextMessageDialogOptions> {
  protected readonly okData = {
    // TODO OK translate!
    text: this.getData('okText') ?? 'COMMON.BUTTONS.OK',
    translate: this.getData('translateOkText'),
  };

  protected onClickOk() {
    this.dynamicDialogRef.close('OK' satisfies MessageDialogResult);
  }
}
