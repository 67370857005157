import { Directive, inject } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Directive({})
export abstract class AbstractDialogTemplateComponent<OPTIONS_TYPE> {
  readonly #dynamicDialogRef = inject(DynamicDialogRef);
  readonly #dialogService = inject(DialogService);
  readonly #instance = this.#dialogService.getInstance(this.#dynamicDialogRef);

  protected get dynamicDialogRef() {
    return this.#dynamicDialogRef;
  }

  protected get instance() {
    return this.#instance;
  }

  protected getData<K extends keyof OPTIONS_TYPE>(key: K): OPTIONS_TYPE[K] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.#instance?.data?.[key] ?? (this.#instance?.config as any)?.[key] ?? undefined;
  }
}
