import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AbstractDialogTemplateComponent } from '../base/abstract-dialog-template.component';
import { ConfirmMessageTextDialogOptions, MessageDialogResult } from '../../typing';
import { DialogBaseFooterComponent } from '../base/dialog-base-footer.component';
import { TranslocoService } from '@jsverse/transloco';
import { isNil } from '@camino-solutions/utils/typeguard';

@Component({
  selector: 'cs-core-message-dialog-message-dialog-confirm-footer',
  standalone: true,
  imports: [DialogBaseFooterComponent],
  template: `<cs-core-message-dialog-dialog-base-footer
    [cancelData]="cancelData"
    [okData]="okData"
    (cancel)="onClickCancel()"
    (ok)="onClickOk()"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogConfirmFooterComponent extends AbstractDialogTemplateComponent<ConfirmMessageTextDialogOptions> {
  readonly #translocoService = inject(TranslocoService);

  protected readonly okData: { text: string; translate?: boolean };
  protected readonly cancelData: { text: string; translate?: boolean };

  constructor() {
    super();

    const okText = this.getData('okText');
    if (isNil(okText)) {
      this.okData = {
        text: this.#translocoService.translate('COMMON.BUTTONS.OK'),
        translate: false,
      };
    } else {
      this.okData = {
        text: okText,
        translate: this.getData('translateOkText'),
      };
    }

    const cancelText = this.getData('cancelText');
    if (isNil(cancelText)) {
      this.cancelData = {
        text: this.#translocoService.translate('COMMON.BUTTONS.CANCEL'),
        translate: false,
      };
    } else {
      this.cancelData = {
        text: cancelText,
        translate: this.getData('translateCancelText'),
      };
    }
  }

  protected onClickCancel() {
    this.dynamicDialogRef.close('CANCEL' satisfies MessageDialogResult);
  }

  protected onClickOk() {
    this.dynamicDialogRef.close('OK' satisfies MessageDialogResult);
  }
}
