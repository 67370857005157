import type { AppMenuItem } from '@camino-solutions/core/app/ui/app-menu';
import { environment } from '@camino-solutions/core/environment';
import { Injector } from '@angular/core';
import { pageFlowCreateRoutePathGenerator } from '@camino-solutions/core/page-flow/router';

export const menu: (injector: Injector) => readonly AppMenuItem[] = (/* injector: Injector */) => {
  // const inject = <T>(token: ProviderToken<T>): T =>
  //   runInInjectionContext(injector, () => angularInject(token));

  return Object.freeze([
    {
      items: [
        {
          label: 'Irányítópult',
          icon: 'pi pi-chart-line',
          items: [
            {
              label: 'Diagramok',
              icon: 'pi pi-chart-bar',
              routerLink: ['/'],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'Cég',
          icon: 'pi pi-building',
          items: [
            {
              label: 'Új',
              icon: 'pi pi-plus',
              routerLink: [pageFlowCreateRoutePathGenerator('company')],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'Projekt',
          icon: 'pi pi-briefcase',
          // accessRule: PlatformProjectsCreateProjectRule.instance,
          items: [
            {
              label: 'Új',
              icon: 'pi pi-plus',
              routerLink: [pageFlowCreateRoutePathGenerator('project')],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'Dokumentum',
          icon: 'pi pi-file',
          items: [
            {
              label: 'Új',
              icon: 'pi pi-plus',
              routerLink: [pageFlowCreateRoutePathGenerator('document')],
            },
          ],
        },
      ],
    },
    {
      // label: 'Dokumentum',
      items: [
        {
          label: 'Előfizetés',
          icon: 'pi pi-wallet',
          items: [
            {
              label: 'Fix',
              icon: 'pi pi-minus',
              routerLink: ['/subscriptions/fixed'],
            },
            {
              label: 'Rugalmas',
              icon: 'pi pi-wave-pulse',
              routerLink: ['/subscriptions/flexible'],
            },
          ],
        },
      ],
    },
    ...(['development', 'local-docker'].indexOf(environment.name) > -1
      ? [
          // {
          //   label: 'ttttt',
          //   items: [
          //     {
          //       label: 'ttttt',
          //       command: () => {
          //         const router = inject(Router);
          //         const queryParams = {
          //           options: KeyValueStore.create(addMinutes(new Date(), 5)).add(
          //             'EstimationImportContext',
          //             new EstimationImportContext({ tenderId: 'mockTenderId' }),
          //           ),
          //         };

          //         router.navigate(['/import'], { queryParams });
          //       },
          //     },
          //   ],
          // },

          {
            label: 'PIVOT',
            items: [
              {
                label: 'PIVOT',
                icon: 'pi pi-download',
                routerLink: ['/pivot'],
              },
            ],
          },
          {
            label: 'Dani',
            items: [
              {
                label: 'Demo download',
                icon: 'pi pi-download',
                routerLink: ['/dani'],
              },
            ],
          },
        ]
      : []),
    // {
    //   label: 'Test',
    //   // accessRule: TestRule.instance,
    //   items: [
    //     {
    //       label: 'Test form',
    //       routerLink: ['/testform'],
    //       // accessRule: TestRule.instance,
    //     },
    //   ],
    // },
    // {
    //   label: 'Session panel',
    //   items: [
    //     {
    //       label: 'Debug',
    //       routerLink: ['/session-test-panel'],
    //     },
    //   ],
    // },
  ]);
};
