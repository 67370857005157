import { Route } from '@angular/router';
// import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: '',
    loadComponent: () => import('@camino-solutions/core/app/ui/app-layout'),
    // providers: [
    //   provideTranslocoScope({
    //     // TODO csinalni egy globalis i18n csomagot, oda kell felvenni az osszes kulcsot es azt importalni mindenhova,
    //     // igy elerheto lenne mindenhonnan es nem kene csomagonkent megegy i18n csomag
    //     scope: 'app/ui/app-layout',
    //     alias: 'appLayout',
    //   }),
    // ],
    // canActivateChild:[AutoLoginPartialRoutesGuard],
    // canActivateChild: [
    //   (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    //     const authService = inject(AuthService);
    //     return authService.isDoneLoadingObservable.pipe(
    //       filter(isDone => isDone),
    //       switchMap(() => authService.isAuthenticatedObservable),
    //       tap(isAuthenticated => {
    //         if(!isAuthenticated) {
    //           console.error('llldldldlddl')
    //           authService.login(state.url);
    //         }
    //       })
    //     );
    //     //authService.canActivateProtectedRoutes$() ? true : authService.login(state.url);
    //   },
    // ],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.component'),
        data: { breadcrumb: 'Dashboard' },
      },
      // { path: 'improbable', loadComponent: () => import('./dashboard/d1.component') },
      // { path: 'ngxgrpc', loadComponent: () => import('./dashboard/d2.component') },
      {
        path: 'subscriptions',
        loadChildren: () => import('@camino-solutions/domain/subscription/ui'),
      },
      {
        path: 'page-flow',
        loadChildren: () => import('@camino-solutions/core/page-flow/ui').then(m => m.routes),
      },
      // { path: '', loadChildren: () => import('@camino-solutions/playground').then(m => m.TestFormRoutes) },
      // {
      //   path: '',
      //   loadChildren: () =>
      //     import('@camino-solutions/playground').then(m => m.SessionTestPanelRoutes),
      // },
      {
        path: 'pivot',
        loadChildren: () => import('@camino-solutions/feature/pivot/router').then(m => m.routes),
      },
      // { path: '', loadChildren: () => import('@camino-solutions/core/auth/ui').then(m => m.routes) },
      {
        path: '',
        loadChildren: () => import('@camino-solutions/core/user/permission').then(m => m.routes),
      },
      {
        path: 'import',
        loadChildren: () => import('@camino-solutions/feature/local-import/ui').then(m => m.routes),
      },
      {
        path: 'dani',
        loadComponent: () => import('./dani/dani.component'),
      },
      {
        path: 'profile',
        loadChildren: () => import('@camino-solutions/domain/profile/ui'),
      },
    ],
  },
];
