import { inject, Injectable, OnDestroy } from '@angular/core';
import { assertIsNil, assertIsNotNil } from '@camino-solutions/utils/assert';
import { MessageDialogOptions } from './typing';
import { DialogService, DynamicDialogComponent, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageDialogConfirmFooterComponent } from './dialog-types/confirm/message-dialog-confirm-footer.component';
import { MessageDialogTextContentComponent } from './dialog-types/text/message-dialog-text-content.component';
import { MessageDialogTextFooterComponent } from './dialog-types/text/message-dialog-text-footer.component';
import { TranslocoService } from '@jsverse/transloco';
import { isNil } from '@camino-solutions/utils/typeguard';

// Fix: kivettuk a fuggvenyt, mert nem hasznaljuk ezt a feature-t viszont bugos a primeng kodja es nem vizsgalja ezt az esetet
// eslint-disable-next-line @typescript-eslint/no-empty-function
DynamicDialogComponent.prototype.loadChildComponent = () => {};

// Singleton pattern(statikus tag-val nem megy: TS18036)
let INSTANCE: MessageDialogService | undefined;

export function getMessageDialogService() {
  assertIsNotNil(INSTANCE);
  return INSTANCE;
}

export function openUnknownErrorDialog(errorCode?: string) {
  const translatePrefix = 'COMMON.MESSAGE_DIALOG.UNKNOWN_ERROR.';
  return getMessageDialogService().open({
    type: 'TEXT',
    messageType: 'ERROR',
    header: `${translatePrefix}HEADER`,
    content: `${translatePrefix}CONTENT`,
    contentParams: { errorCode: errorCode ?? 'Unknown code' },
  }).onClose;
}

@Injectable({ providedIn: 'root' })
export class MessageDialogService implements OnDestroy {
  readonly #dialogService = inject(DialogService);
  readonly #translocoService = inject(TranslocoService);

  constructor() {
    assertIsNil(INSTANCE);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    INSTANCE = this;
  }

  open<T>(options: MessageDialogOptions<T>) {
    let dynamicDialogConfig: DynamicDialogConfig<T>;

    switch (options.type) {
      case 'CONFIRM':
        dynamicDialogConfig = {
          //defaults
          closable: false,
          ...options,
          // TODO ?
          contentStyle: { overflow: 'auto' },
          // TODO erre szukseg van?
          // breakpoints: {
          //   '960px': '75vw',
          //   '640px': '90vw',
          // },
          templates: {
            content: MessageDialogTextContentComponent,
            footer: MessageDialogConfirmFooterComponent,
          },
        };
        break;
      case 'CONFIRM_COMPONENT':
        dynamicDialogConfig = {
          //defaults
          closable: false,
          ...options,
          // TODO ?
          contentStyle: { overflow: 'auto' },
          templates: {
            content: options.contentComponent,
            footer: MessageDialogConfirmFooterComponent,
          },
        };
        break;
      case 'TEXT':
        dynamicDialogConfig = {
          //defaults
          closable: false,
          ...options,
          // TODO ?
          contentStyle: { overflow: 'auto' },
          templates: {
            content: MessageDialogTextContentComponent,
            footer: MessageDialogTextFooterComponent,
          },
        };
        break;
      case 'COMPONENT':
        dynamicDialogConfig = {
          //defaults
          closable: false,
          ...options,
          // TODO ?
          contentStyle: { overflow: 'auto' },
          templates: {
            content: options.contentComponent,
            // TODO ha nincs akkor buttons -t kell figyelembe venni
            footer: options.footerComponent,
          },
        };
        break;

      default:
        throw new Error('[MessageDialogService::open] Not found option type');
    }

    if ((options?.translateHeader ?? true) === true && !isNil(options.header)) {
      dynamicDialogConfig.header = this.#translocoService.translate(
        options.header,
        options.headerParams,
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.#dialogService.open(null as any, dynamicDialogConfig);
  }

  ngOnDestroy() {
    INSTANCE = undefined;
  }
}
