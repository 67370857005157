import { Provider } from '@angular/core';
import {
  IMPORT_DATA_PROCESSOR_WORKER_FACTORY_PROVIDER_TOKEN,
  setThrowOverrideHandler,
} from '@camino-solutions/feature/local-import/service-worker';

export const workerFactory = () => {
  // register throw deserializer (2xer kell bejegyezni :( )
  setThrowOverrideHandler();

  return () => new Worker(new URL('./local-import.worker', import.meta.url));
};

export const localImportProcessorWorkerFactoryProvider: Provider = {
  provide: IMPORT_DATA_PROCESSOR_WORKER_FACTORY_PROVIDER_TOKEN,
  useFactory: workerFactory,
};
