import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'cs-core-message-dialog-dialog-base-footer',
  standalone: true,
  imports: [ButtonDirective, TranslocoPipe],
  host: {
    class: 'flex w-full justify-end',
  },
  template: `@let cancelDataValue = cancelData();
    @let okDataValue = okData();
    @if (cancelDataValue !== undefined) {
      <button type="button" pButton outlined="true" (click)="cancel.emit()">
        @if ((cancelDataValue?.translate ?? true) === true) {
          {{ cancelDataValue.text | transloco }}
        } @else {
          {{ cancelDataValue.text }}
        }
      </button>
    }
    @if (okDataValue !== undefined) {
      <button type="button" pButton (click)="ok.emit()">
        @if ((okDataValue?.translate ?? true) === true) {
          {{ okDataValue.text | transloco }}
        } @else {
          {{ okDataValue.text }}
        }
      </button>
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBaseFooterComponent {
  readonly cancelData = input<{ text: string; translate?: boolean } | undefined>();
  readonly okData = input<{ text: string; translate?: boolean } | undefined>();

  readonly cancel = output();
  readonly ok = output();
}
