import { CanDeactivateFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { inject } from '@angular/core';
import { PageFlowFreeResources } from './page-flow-free-resources.action';
import { map } from 'rxjs/operators';

export const pageFlowFreeResourcesDeactivateGuard: CanDeactivateFn<never> = () => {
  return (
    inject(Store)
      .dispatch(new PageFlowFreeResources())
      // TODO megnezni hogy a translate torlodik-e
      // delay(100),
      .pipe(map(() => true))
  );
};
