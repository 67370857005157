import { FileType } from '../typing/file.type';
import { FileTypeEnum } from '../typing/file-type.enum';

export const EXCEL_FILE_TYPE = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const CSV_FILE_TYPE = ['text/plain', 'text/csv'];

export const ExcelFileType: FileType = {
  name: FileTypeEnum.EXCEL,
  types: EXCEL_FILE_TYPE,
  extensions: ['xlsx', 'xls'],
  contentType: 'binary',
};

export const CsvFileType: FileType = {
  name: FileTypeEnum.CSV,
  types: CSV_FILE_TYPE,
  extensions: ['csv'],
  contentType: 'text',
};

export const FILE_TYPES = [CsvFileType, ExcelFileType];
