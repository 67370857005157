import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { SessionMessage, TaskEvent } from '@camino-solutions/share/proto/common/session';
import { SessionStateModel, SessionTask } from './session-state.model';
import { SESSION_STATE_TOKEN } from './session-state.token';
import { produce, WritableDraft } from 'immer';
import { isNil } from '@camino-solutions/utils/typeguard';
import {
  SessionActionTypes,
  SessionAddMessageAction,
  SessionChangeSessionMessageAction,
  SessionConnectedAction,
  SessionConnectingAction,
  SessionCreateTaskAction,
  SessionDisconnectedAction,
  SessionErrorWhenGetTaskIdAction,
  SessionReConnectingAction,
  SessionRemoveFinishedTasksAction,
  SessionRemoveTaskAction,
  SessionSetTaskIdAction,
} from './action/session';
import { ToggleSessionPanelAction } from './action/panel';

@State<SessionStateModel>({
  name: SESSION_STATE_TOKEN,
  defaults: {
    firstLoading: true,
    connectionState: 'disconnected',
    tasks: [],
    panelIsOpen: false,
    // sessionMessages: [],
  },
})
@Injectable()
export class SessionState {
  @Selector([SessionState])
  static firstLoading({ firstLoading }: SessionStateModel): SessionStateModel['firstLoading'] {
    return firstLoading;
  }

  @Selector([SessionState])
  static connectionState({
    connectionState,
  }: SessionStateModel): SessionStateModel['connectionState'] {
    return connectionState ?? 'disconnected';
  }

  @Selector([SessionState.connectionState])
  static connected(connectionState: SessionStateModel['connectionState']): boolean {
    return connectionState === 'connected';
  }

  @Selector([SessionState])
  static hasRunningTask({ tasks }: SessionStateModel): boolean {
    return tasks.some(task => isNil(task.sessionMessage));
  }

  @Selector([SessionState])
  static panelIsOpen({ panelIsOpen }: SessionStateModel): SessionStateModel['panelIsOpen'] {
    return panelIsOpen;
  }

  static taskByTaskId(taskId: SessionTask['taskId']) {
    return createSelector([SessionState], (state: SessionStateModel): SessionTask | undefined => {
      return state.tasks.find(task => task.taskId === taskId);
    });
  }

  // static watchIncomingMessage(taskId: SessionTask['taskId']) {
  //   return createSelector(
  //     [SessionState],
  //     (state: SessionStateModel): SessionMessage | undefined => {
  //       return state.tasks.find(task => task.taskId === taskId)?.sessionMessage;
  //     },
  //   );
  // }

  // TODO ez a megfelelo modszer? vagy lehet a https://www.ngxs.io/concepts/select/optimizing-selectors#implementation jobb lenne?
  static tasks(sortOrder: 'created' | 'createdReverse' = 'createdReverse') {
    // console.log('tasks');
    // return createSelector([SessionState], (state: SessionStateModel): Session[] => {
    //   console.log('sort');
    //   if (sortOrder === 'created') {
    //     const tasks = Object.values(state.tasks);
    //     tasks.sort((a, b) => a.created - b.created);
    //     return tasks;
    //   }
    //   throw new Error(`Unsupported tasks selector sort order: ${sortOrder}`);
    // });
    return createSelector([SessionState], (state: SessionStateModel): SessionTask[] => {
      if (sortOrder === 'created') {
        // const tasks = Array.from(state.tasks.values());
        // tasks.sort((a, b) => a.created - b.created);
        // return tasks;
        return state.tasks;
      } else if (sortOrder === 'createdReverse') {
        return [...state.tasks].reverse();
      }
      throw new Error(`Unsupported tasks selector sort order: ${sortOrder}`);
    });
  }

  @Action(SessionCreateTaskAction)
  createTask(
    { setState }: StateContext<SessionStateModel>,
    { referenceId }: SessionCreateTaskAction,
  ) {
    setState(
      produce(draft => {
        draft.tasks.push({
          referenceId,
          state: 'run',
          progress: undefined /*, created: new Date().getTime()*/,
        });
      }),
    );
  }

  @Action(SessionSetTaskIdAction)
  setTaskId(
    { setState }: StateContext<SessionStateModel>,
    { referenceId, taskId }: SessionSetTaskIdAction,
  ) {
    setState(
      produce(draft => {
        const foundTask = draft.tasks.find(
          ({ referenceId: taskReferenceId }) => taskReferenceId === referenceId,
        );
        if (isNil(foundTask)) {
          throw new Error(`Not found task by reference id: ${referenceId}`);
        }
        foundTask.taskId = taskId;
      }),
    );
  }

  @Action(SessionAddMessageAction)
  addMessage(ctx: StateContext<SessionStateModel>, { sessionMessage }: SessionAddMessageAction) {
    ctx.setState(
      produce(draft => {
        if (draft.firstLoading === true) {
          // TODO csak egy helyen kene kezelni!
          draft.firstLoading = false;
        }
      }),
    );
    // if (sessionMessage.data === SessionMessage.DataCase.control && !isNil(sessionMessage.control)) {
    //   if (
    //     sessionMessage.control?.scenario === SessionControl.ScenarioCase.opened &&
    //     !isNil(sessionMessage.control.session)
    //   ) {
    //     // Elvileg az elso hivas kell hogy legyen.
    //     draft.sessionInfo = sessionMessage.control.session;
    //   }
    // } else
    switch (sessionMessage.task?.scenario) {
      case TaskEvent.ScenarioCase.start:
        // console.log('case TaskEvent.ScenarioCase.start');
        break;
      case TaskEvent.ScenarioCase.progress:
        this.#handleSessionProgress(sessionMessage, ctx);
        break;
      case TaskEvent.ScenarioCase.finish:
        this.#handleSessionFinish(sessionMessage, ctx);
        break;
      case TaskEvent.ScenarioCase.remove:
        this.#handleSessionRemove(sessionMessage, ctx);
        break;
      case TaskEvent.ScenarioCase.failure:
        this.#handleSessionFailure(sessionMessage, ctx);
        break;
    }
  }

  @Action(SessionChangeSessionMessageAction)
  sessionChangeTask(
    { setState }: StateContext<SessionStateModel>,
    { taskId, sessionMessage }: SessionChangeSessionMessageAction,
  ) {
    setState(
      produce(draft => {
        const foundTask = draft.tasks.find(({ taskId: id }) => id === taskId);
        if (isNil(foundTask)) {
          throw new Error(
            `[SessionState] Not found task in session: ${taskId}`, //, but run auto fix create`,
          );
        }
        foundTask.sessionMessage = sessionMessage;
      }),
    );
  }

  @Action(SessionDisconnectedAction)
  disconnected({ setState, getState }: StateContext<SessionStateModel>) {
    const action: SessionActionTypes = 'disconnected';
    if (getState().connectionState !== action) {
      setState(
        produce(draft => {
          draft.connectionState = action;
        }),
      );
    }
  }

  @Action(SessionConnectingAction)
  connecting({ setState, getState }: StateContext<SessionStateModel>) {
    const action: SessionActionTypes = 'connecting';
    if (getState().connectionState !== action) {
      setState(
        produce(draft => {
          draft.connectionState = action;
        }),
      );
    }
  }

  @Action(SessionReConnectingAction)
  reConnecting({ setState, getState }: StateContext<SessionStateModel>) {
    const action: SessionActionTypes = 'reConnecting';
    if (getState().connectionState !== action) {
      setState(
        produce(draft => {
          draft.connectionState = action;
        }),
      );
    }
  }

  @Action(SessionConnectedAction)
  connected({ setState, getState }: StateContext<SessionStateModel>) {
    const action: SessionActionTypes = 'connected';
    const state = getState();
    if (state.connectionState !== action) {
      setState(
        produce(draft => {
          // draft.tasks = [];
          draft.connectionState = action;
        }),
      );
    }
    if (state.firstLoading === true) {
      setState(
        produce(draft => {
          draft.firstLoading = false;
        }),
      );
    }
  }

  // @Action(SessionTempSendRequestAction)
  // sessionTempSendRequest(ctx: StateContext<SessionStateModel>, { request }: SessionTempSendRequestAction) {
  //   // this.#testStreamerClient.pushRequest(request).subscribe(response => {
  //   //   console.log(`PUSH REQUEST RESPONSE: ${response.toProtobufJSON()}`);
  //   // });
  // }

  @Action(SessionRemoveFinishedTasksAction)
  removeFinishedTasks({ getState, dispatch }: StateContext<SessionStateModel>) {
    return dispatch(
      getState()
        .tasks.filter(task => isNil(task.sessionMessage))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(({ taskId }) => new SessionRemoveTaskAction(taskId!)),
    );
    // setState(
    //   produce(draft => {
    // draft.tasks = draft.tasks.filter(task => isNil(task.sessionMessage));
    // })
    // );
  }

  @Action(SessionRemoveTaskAction)
  removeTask({ setState }: StateContext<SessionStateModel>, { taskId }: SessionRemoveTaskAction) {
    setState(
      produce(draft => {
        const tasks = draft.tasks;
        const index = tasks.findIndex(task => task.taskId === taskId);
        if (index !== -1) {
          tasks.splice(index, 1);
        } else {
          throw new Error(
            `[SessioState::SessionRemoveTaskAction] Not found remove task: ${taskId}`,
          );
        }
        draft.tasks = tasks;
      }),
    );
  }

  @Action(ToggleSessionPanelAction)
  toggleSessionPanel({ setState }: StateContext<SessionStateModel>) {
    setState(
      produce(draft => {
        draft.panelIsOpen = !draft.panelIsOpen;
      }),
    );
  }

  /**
   * Olyan eset amikor a request hibara futott es nem kapott task id-t
   * @param setState
   * @param referenceId
   */
  @Action(SessionErrorWhenGetTaskIdAction)
  sessionErrorWhenGetTaskId(
    { setState }: StateContext<SessionStateModel>,
    { referenceId }: SessionErrorWhenGetTaskIdAction,
  ) {
    setState(
      produce(draft => {
        const task = draft.tasks.find(
          ({ referenceId: taskReferenceId }) => taskReferenceId === referenceId,
        );
        if (isNil(task)) {
          throw new Error(
            `[SessionState::SessionErrorWhenGetTaskIdAction] Not found task by referenceId: ${referenceId}`,
          );
        } else {
          task.state = 'create-error';
        }
      }),
    );
  }

  #handleSessionFinish(sessionMessage: SessionMessage, ctx: StateContext<SessionStateModel>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const taskId = sessionMessage.task!.finish!.task!.taskId;

    ctx.setState(
      produce(draft => {
        const foundTask = this.#findTask(draft.tasks, taskId);
        if (isNil(foundTask)) {
          // check, ha nem letezik a task akkor letrehozzuk
          // const referenceId = v7();
          // foundTask = { referenceId, taskId, state: 'finish', progress: 100 } satisfies SessionTask;
          // draft.tasks.push(foundTask);
          console.info(
            `[SessionState::#handleSessionFinish] Not found task in session: ${taskId}, but run auto fix create`,
          );
        } else {
          foundTask.state = 'finish';
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { close, interact } = sessionMessage.task!.finish!;
          if (!isNil(close)) {
            foundTask.autoClose = close;
          } else if (!isNil(interact)) {
            // TODO handle interact
          }
        }
      }),
    );

    return ctx.dispatch(new SessionChangeSessionMessageAction(taskId, sessionMessage));
  }

  #findTask(tasks: WritableDraft<SessionTask>[], taskId: string) {
    return tasks.find(({ taskId: id }) => id === taskId);
  }

  #handleSessionRemove(sessionMessage: SessionMessage, ctx: StateContext<SessionStateModel>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const taskId = sessionMessage.task!.remove!.taskId;

    return ctx.dispatch(new SessionRemoveTaskAction(taskId));
  }

  #handleSessionFailure(sessionMessage: SessionMessage, ctx: StateContext<SessionStateModel>) {
    ctx.setState(
      produce(draft => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const taskId = sessionMessage.task!.failure!.task!.taskId;
        const foundTask = this.#findTask(draft.tasks, taskId);
        if (!isNil(foundTask)) {
          foundTask.sessionMessage = sessionMessage;
          foundTask.state = 'error';
        } else {
          console.info(
            `[SessionState::#handleSessionFailure] Not found task in session: ${taskId}, but run auto fix create`,
          );
        }
      }),
    );
  }

  #handleSessionProgress(sessionMessage: SessionMessage, ctx: StateContext<SessionStateModel>) {
    const progressTasks = sessionMessage.task?.progress?.tasks ?? [];
    ctx.setState(
      produce(draft => {
        progressTasks.forEach(({ taskId, progressRatio }) => {
          const foundTask = draft.tasks.find(task => task.taskId === taskId);
          if (isNil(foundTask)) {
            console.error(
              `[SessionState::#handleSessionProgress] Not found task in session: ${taskId}`,
            );
          } else {
            foundTask.progress = progressRatio;
          }
        });
      }),
    );
  }
}
