import {
  BehaviorSubject,
  filter,
  map,
  mergeMap,
  Subject,
  Subscription,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { isNil } from '@camino-solutions/utils/typeguard';
import { SchedulerTaskDefinition } from './scheduler-task-definition';
import { SchedulerTask } from './scheduler-task';

type TaskId<D> = SchedulerTaskDefinition<D>['taskId'];

/**
 * TODO full altalanositasni! ne fuggjon semmilyen session cucctol!
 */
export class TaskScheduler<D> {
  readonly #completed$ = new Subject<TaskId<D>>();
  readonly #completedAll$ = new Subject<void>();
  readonly #taskScheduler$ = new Subject<SchedulerTaskDefinition<D>>();
  readonly #activeTasks$ = new BehaviorSubject<Set<SchedulerTaskDefinition<D>>>(new Set());
  readonly #removeSignal$ = new Subject<string>();
  #subscription: Subscription | null = null;

  get completed$() {
    return this.#completed$.asObservable();
  }

  get completedAll$() {
    return this.#completedAll$.asObservable();
  }

  /**
   * Function to start the subscription if not already started
   * @private
   */
  #startSubscription() {
    if (this.#subscription === null) {
      this.#subscription = this.#taskScheduler$
        .pipe(
          map(
            definition =>
              ({
                definition: { ...definition, started: new Date().getTime() },
                completed: false,
              }) satisfies SchedulerTask<D>,
          ),
          mergeMap(task => {
            // add task to active tasks
            this.#updateActiveTasks(task.definition, true);

            return timer(task.definition.delay).pipe(
              takeUntil(
                this.#removeSignal$.pipe(
                  filter(taskId => taskId === task.definition.taskId),
                  tap(() => this.#updateActiveTasks(task.definition, false)),
                ),
              ),
              map(() => ({ ...task, completed: true })),
            );
          }),
        )
        .subscribe(task => {
          if (task.completed) {
            // remove task from active tasks
            this.#completed$.next(task.definition.taskId);
            this.#updateActiveTasks(task.definition, false);
            task.definition.completeCallback();
            if (this.#activeTasks$.getValue().size === 0) {
              this.#completedAll$.next();
              // stop subscription if no active tasks left
              this.#stopSubscription();
            }
          }
        });
    }
  }

  #stopSubscription() {
    if (this.#subscription !== null) {
      this.#subscription.unsubscribe();
      this.#subscription = null;
    }
  }

  // Function to update the active tasks set
  #updateActiveTasks(task: SchedulerTaskDefinition<D>, isActive: boolean) {
    const activeTasks = this.#activeTasks$.getValue();
    if (isActive) {
      activeTasks.add(task);
    } else {
      activeTasks.delete(task);
    }
    this.#activeTasks$.next(activeTasks);
  }

  getActiveTasks(): SchedulerTaskDefinition<D>[] {
    return Array.from(this.#activeTasks$.getValue().values());
  }

  getActiveTaskIds(): TaskId<D>[] {
    return this.getActiveTasks().map(({ taskId }) => taskId);
  }

  isActive(taskId: TaskId<D>): boolean {
    return this.getActiveTasks().some(task => task.taskId === taskId);
  }

  getActive(taskId: TaskId<D>): SchedulerTaskDefinition<D> | undefined {
    return this.getActiveTasks().find(({ taskId: activeTaskId }) => activeTaskId === taskId);
  }

  addTasks(
    newTasks:
      | Omit<SchedulerTaskDefinition<D>, 'started'>
      | Omit<SchedulerTaskDefinition<D>, 'started'>[],
  ): void {
    if (Array.isArray(newTasks)) {
      if (newTasks.length > 0) {
        this.#startSubscription();
        newTasks.forEach(task => this.#taskScheduler$.next(task as SchedulerTaskDefinition<D>));
      }
    } else if (!isNil(newTasks)) {
      this.#startSubscription();
      this.#taskScheduler$.next(newTasks as SchedulerTaskDefinition<D>);
    }
  }

  removeTasks(taskIds: TaskId<D> | TaskId<D>[]): void {
    if (Array.isArray(taskIds)) {
      taskIds.forEach(taskId => this.#removeSignal$.next(taskId));
    } else {
      this.#removeSignal$.next(taskIds);
    }
  }
}
