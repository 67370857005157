import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractDialogTemplateComponent } from '../base/abstract-dialog-template.component';
import { TextMessageDialogOptions } from '../../typing';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'cs-core-message-dialog-message-dialog-text-content',
  standalone: true,
  imports: [TranslocoPipe],
  template: `<p>
    @if ((translateContent ?? true) === true) {
      {{ content | transloco: contentParams }}
    } @else {
      {{ content }}
    }
  </p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogTextContentComponent extends AbstractDialogTemplateComponent<TextMessageDialogOptions> {
  protected readonly content = this.getData('content');
  protected readonly contentParams = this.getData('contentParams');
  protected readonly translateContent = this.getData('translateContent');
}
