import { transferHandlers } from 'comlink';
import {
  AssertException,
  AssertIsArrayException,
  AssertIsIntException,
  AssertIsNilException,
  assertIsNotNil,
  AssertIsNotNilException,
} from '@camino-solutions/utils/assert';

export const setThrowOverrideHandler = () => {
  const originalThrowHandler = transferHandlers.get('throw');
  assertIsNotNil(originalThrowHandler);

  transferHandlers.set('throw', {
    canHandle: originalThrowHandler.canHandle,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    serialize(obj: any) {
      const exception = obj.value;
      const isError = exception instanceof Error;
      let serialized = exception;
      let type:
        | 'ERROR'
        | 'ASSERT_IS_ARRAY'
        | 'ASSERT_IS_INT'
        | 'ASSERT_IS_NIL'
        | 'ASSERT_IS_NOT_NIL' = 'ERROR';

      if (exception instanceof AssertException) {
        if (exception instanceof AssertIsArrayException) {
          type = 'ASSERT_IS_ARRAY';
        } else if (exception instanceof AssertIsIntException) {
          type = 'ASSERT_IS_INT';
        } else if (exception instanceof AssertIsNilException) {
          type = 'ASSERT_IS_NIL';
        } else if (exception instanceof AssertIsNotNilException) {
          type = 'ASSERT_IS_NOT_NIL';
        }
      }
      if (isError) {
        serialized = {
          isError,
          type,
          value: {
            message: exception.message,
            stack: exception.stack,
            type: exception.name,
          },
          serializedData: JSON.stringify(exception),
        };
      }
      return [serialized, []];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(obj: any) {
      if (obj.isError) {
        if (obj.type === 'ERROR') {
          throw Object.assign(new Error(obj.value.message), obj.value);
        } else if (obj.type === 'ASSERT_IS_ARRAY') {
          throw Object.assign(new AssertIsArrayException(obj.value.message), obj.value);
        } else if (obj.type === 'ASSERT_IS_INT') {
          throw Object.assign(new AssertIsIntException(obj.value.message), obj.value);
        } else if (obj.type === 'ASSERT_IS_NIL') {
          throw Object.assign(new AssertIsNilException(obj.value.message), obj.value);
        } else if (obj.type === 'ASSERT_IS_NOT_NIL') {
          throw Object.assign(new AssertIsNotNilException(obj.value.message), obj.value);
        }
      }
      throw obj.value;
    },
  });
};
