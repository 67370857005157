import { Inject, inject, Injectable, NgZone, OnDestroy, signal } from '@angular/core';
import { type Remote, wrap } from 'comlink';
import { isNil } from '@camino-solutions/utils/typeguard';
import { assertIsNotNil } from '@camino-solutions/utils/assert';
import type { DirectSource, ImportRow } from '@camino-solutions/share/proto/projects/platform';
import { ImportProcessFacade } from '../processor/import-processor.facade';
import { AbstractLocalImportProcessorWorker } from '../worker/abstract-local-import-processor.worker';
import { IMPORT_DATA_PROCESSOR_WORKER_FACTORY_PROVIDER_TOKEN } from './local-import-processor-worker-factory-provider.token';
import { FileType } from '../typing/file.type';
import { AssociatedHeader } from '../typing/associate-header';
import { RowError } from '../typing/row-error';
import { WrapNonPromiseMethodsInPromise } from '@camino-solutions/utils/typing';

/**
 * Ezen keresztul vezereljuk a worker-t
 * Ez az osztaly felelos a betoltesert es az elpusztitasert is,
 * tovabba ImportProcessFacade -n keresztul biztositjuk a bridge metodusokat
 */
@Injectable()
export class LocalImportProcessorBridgeService
  implements WrapNonPromiseMethodsInPromise<ImportProcessFacade>, OnDestroy
{
  readonly #workerFactory: () => Worker;
  #workerProxy: Remote<AbstractLocalImportProcessorWorker> | undefined;
  #rawWorker: Worker | undefined;
  readonly #ngZone = inject(NgZone);
  readonly #isReady = signal(false);

  get isReady() {
    return this.#isReady.asReadonly();
  }

  constructor(
    @Inject(IMPORT_DATA_PROCESSOR_WORKER_FACTORY_PROVIDER_TOKEN) workerFactory: () => Worker,
  ) {
    this.#workerFactory = workerFactory;
  }

  async ngOnDestroy() {
    // if (isNil(this.#workerProxy) === false) {
    //   delete this.#workerProxy;
    // }
    // safe remove, without error :(
    await Promise.resolve(null).then(() => {
      this.#rawWorker?.terminate();
    });
  }

  setSource(source: DirectSource) {
    if (isNil(this.#workerProxy)) {
      void this.#ngZone.runOutsideAngular(() => this.#initWorker(source));
    } else {
      void this.#workerProxy.updateSource(source.toObject());
    }
  }

  async #initWorker(source: DirectSource) {
    if (this.#rawWorker !== undefined) {
      return;
    }
    this.#rawWorker = this.#workerFactory();
    const workerProxy = wrap<AbstractLocalImportProcessorWorker>(this.#rawWorker);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.#workerProxy = await new workerProxy(source.toObject());
    this.#isReady.set(true);
  }

  getAcceptFileTypes(): Promise<FileType[]> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.getAcceptFileTypes();
  }

  checkFileType(file: File): Promise<boolean> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.checkFileType(file);
  }

  setFile(file: File): Promise<string> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.setFile(file);
  }

  getHeaders(): Promise<string[]> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.getHeaders();
  }

  calculateAssociatedHeaders(): Promise<AssociatedHeader[] | undefined> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.calculateAssociatedHeaders();
  }

  validateAssociatedHeaders(): Promise<boolean> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.validateAssociatedHeaders();
  }

  overrideAssociatedHeaders(headers: AssociatedHeader[]): Promise<void> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.overrideAssociatedHeaders(headers);
  }

  submit(): Promise<ImportRow.AsObject[] | RowError> {
    assertIsNotNil(this.#workerProxy);
    return this.#workerProxy.submit();
  }
}
